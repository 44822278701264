import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromAppSession from './app-session.reducer';

export const selectAppSessionState = createFeatureSelector<fromAppSession.State>(
  fromAppSession.appSessionFeatureKey,
);

export const isMobileView = createSelector(
  selectAppSessionState,
  (s) => s.viewport.width < 992, // the width that mobile menu will be shown.
);

export const isDesktopView = createSelector(isMobileView, (isMobileView) => !isMobileView);

export const isAppInitialized = createSelector(
  selectAppSessionState,
  (s) => s.appState !== fromAppSession.AppState.initializing,
);

export const selectMainErrorMessage = createSelector(
  selectAppSessionState,
  (s) => s.error?.errorMessage,
);

export const selectHasStickyTopMenu = createSelector(
  selectAppSessionState,
  (s) => s.stickyHeaderClass === 'fix',
);

export const selectAppTxt = createSelector(selectAppSessionState, (s) => s.appTxt);

export const selectIndustryTypes = createSelector(selectAppTxt, (appTxt) => {
  return appTxt['employer-information.industry-types-list'];
});

// TODO: this is an feature flag selector example, customize it based on your application.
export const selectFeatureFlagDisableRecaptcha = createSelector(
  selectAppSessionState,
  (s) => !!s.featureFlags.disableRecaptcha,
);
