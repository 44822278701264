import { createReducer, on } from '@ngrx/store';
import { IFeatureFlags } from 'src/environments/environment.model';

import {
  applicationAuthenticationFailed,
  applicationStartFailed,
  applicationStartSuccess,
  loadAppTxt,
  loadFeatureFlags,
  TopMenuClassChanged,
  ViewPortChanged,
} from './app-session.actions';

export const appSessionFeatureKey = '[awesomeForm]appSession';

export interface ILoginError {
  errorCode: string;
  errorMessage: string;
}

export interface ILoginError {
  errorCode: string;
  errorMessage: string;
}

export const enum AppState {
  initializing,
  success,
  failed,
}

export interface State {
  viewport: { height: number; width: number };
  appState: AppState;
  error: ILoginError | null;
  stickyHeaderClass: string;
  appTxt: any;
  featureFlags: IFeatureFlags;
}

export const initialState: State = {
  viewport: { height: 0, width: 0 },
  appState: AppState.initializing,
  error: null,
  stickyHeaderClass: '',
  appTxt: {},
  featureFlags: {},
};

export const reducer = createReducer(
  initialState,

  on(ViewPortChanged, (state, viewport) => ({ ...state, viewport })),

  on(applicationStartSuccess, (state) => ({
    ...state,
    appState: AppState.success,
  })),

  on(applicationStartFailed, applicationAuthenticationFailed, (state, { error }) => ({
    ...state,
    appState: AppState.failed,
    error,
  })),

  on(TopMenuClassChanged, (state, { className }) => ({
    ...state,
    stickyHeaderClass: className,
  })),

  on(loadFeatureFlags, (state, { featureFlags }) => ({
    ...state,
    featureFlags,
  })),

  on(loadFeatureFlags, (state, { featureFlags }) => ({
    ...state,
    featureFlags,
  })),

  on(loadAppTxt, (state, { appTxt }) => ({
    ...state,
    appTxt,
  })),
);
