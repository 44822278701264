import { createAction, props } from '@ngrx/store';
import { IFeatureFlags } from 'src/environments/environment.model';

import { ILoginError } from './app-session.reducer';

export const TopMenuClassChanged = createAction(
  '[AppSession] Top Menu Class changed',
  props<{ className: string }>(),
);

export const ViewPortChanged = createAction(
  '[AppSession] Viewport changed',
  props<{ width: number; height: number }>(),
);

export const contextMenuClicked = createAction(
  '[AppSession] ContextMenu Clicked',
  props<{ message: string }>(),
);

export const applicationStarts = createAction(
  '[AppSession] Application started',
  props<{ user: any; appTxt: any; featureFlags: IFeatureFlags }>(),
);

export const loadAppTxt = createAction('[AppSession] load App Txt', props<{ appTxt: any }>());

export const loadFeatureFlags = createAction(
  '[AppSession] load Feature Flags',
  props<{ featureFlags: IFeatureFlags }>(),
);

export const applicationStartSuccess = createAction('[AppSession] Application start success');
export const applicationStartFailed = createAction(
  '[AppSession] Application start failed',
  props<{ error: ILoginError }>(),
);

export const applicationAuthenticationFailed = createAction(
  '[AppSession] Application Authentication failed',
  props<{ error: ILoginError; appTxt: any }>(),
);

export const loadLookupsStart = createAction('[AppSession] Start Load Lookups');
export const LoadLookupsSuccess = createAction(
  '[AppSession] Load Lookups Success',
  props<{ data: any }>(),
);
